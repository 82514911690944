// ReceiptConfirmationModal.jsx
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

function ReceiptConfirmationModal({ open, handleClose, handleConfirm, formId }) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Potwierdź przebieg wizyty</DialogTitle>
            <DialogContent>
                <Typography>
                    Czy została wydana recepta dla tego pacjenta?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleConfirm(formId, false)} color="primary">
                    Nie
                </Button>
                <Button onClick={() => handleConfirm(formId, true)} color="primary">
                    Tak
                </Button>
                <Button onClick={handleClose} color="secondary">
                    Anuluj
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReceiptConfirmationModal;
