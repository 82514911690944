import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import MDButton from 'components/MDButton';
import MDBox from "components/MDBox";
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
import robotoBase64 from './Roboto-Regular-normal';

const Transition = React.forwardRef(function Transition(
    props,
    ref,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const PatientDetailsModal = ({ open, handleClose, formId, formData }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard:', text);
                setOpenSnackbar(true); // Open the Snackbar when text is copied
            })
            .catch((err) => {
                console.error('Failed to copy text to clipboard:', err);
            });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleDownload = () => {
        const doc = new jsPDF();

        // Add the custom font
        doc.addFileToVFS('Roboto-Regular.ttf', robotoBase64);
        doc.addFont('Roboto-Regular.ttf', 'roboto', 'normal');
        doc.setFont('roboto');

        const title = `${formData['Imię']} ${formData['Nazwisko']} #${formId}`;
        doc.text(title, 10, 10);

        // Prepare the data for the table
        const tableData = Object.entries(formData).map(([key, value]) => {
            return [key, Array.isArray(value) ? value.join(', ') : value];
        });

        // Generate the table
        doc.autoTable({
            startY: 20,
            head: [['Pole formularza', 'Wartość']],
            body: tableData,
            theme: 'striped',
            headStyles: { fillColor: [22, 160, 133] },
            margin: { top: 10 },
            styles: {
                font: "roboto",
                fontStyle: "bold"
            }
        });

        // Save the PDF
        doc.save(`Dane Pacjenta - ${formId}.pdf`);
    };

    return (formData && <>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ ml: 0, flex: 1 }} variant="h5" component="div">
                        {formData['Imię']} {formData['Nazwisko']} #{formId}
                    </Typography>
                    <MDBox mr={3}>
                        <MDButton
                            size="medium"
                            variant="contained"
                            color="info"
                            onClick={handleDownload}
                        >
                            Pobierz dane
                        </MDButton>
                    </MDBox>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <List>
                {Object.entries(formData).map(([key, value], index) => (
                    <React.Fragment key={key}>
                        <ListItemButton onClick={() => copyToClipboard(Array.isArray(value) ? value.join('\n ') : value)}>
                            <ListItemText
                                primary={Array.isArray(value) ? value.join('\n ') : value}
                                secondary={key}
                            />
                        </ListItemButton>
                        {index < Object.entries(formData).length - 1 && <Divider sx={{ marginY: 0.5 }} />}
                    </React.Fragment>
                ))}
            </List>
        </Dialog>
        <Snackbar
            open={openSnackbar}
            autoHideDuration={1000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity="success" sx={{ width: '100%' }}>
                Skopiowano
            </Alert>
        </Snackbar>
    </>
    );
};

export default PatientDetailsModal;
