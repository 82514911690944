/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import patient from "assets/images/patient.png";

import { useState, useEffect } from "react";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import MDButton from "components/MDButton";
// import { fetchedData } from "./mockData";
import { API_BASE_URL } from "config/api";

import axios from "axios"

export default function data() {
  const auth = useAuthUser()
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  const getRandomDateWithinLastDays = (days) => {
    const now = new Date();
    const randomDaysAgo = getRandomNumber(0, days); // Random number of days ago
    const randomDate = new Date(now.setDate(now.getDate() - randomDaysAgo));
    randomDate.setHours(getRandomNumber(0, 23), getRandomNumber(0, 59), getRandomNumber(0, 59));
    return randomDate.toISOString();
  };

  useEffect(() => {
    const fetchMockData = async () => {
      try {
        const fetchedData = {
          [getRandomNumber(1000, 100000)]: {
            "Imię": "Michał",
            "Nazwisko": "Kwiecień",
            "Email": "michal.kwiecien@example.com",
            "Telefon": "123-456-789",
            "PESEL": "85010112345",
            "Adres": "ul. Kwiatowa 10",
            "Kod pocztowy": "00-001",
            "Miasto": "Warszawa",
            "Płeć": "Mężczyzna",
            "Czy jest to kontynuacja leczenia?": "Tak",
            "Czy jesteś uzależniony/-a od jakichkolwiek substancji albo leków lub zażywasz te substancje obecnie?": "Nie",
            "Czy przyjmujesz obecnie jakiekolwiek leki/preparaty?": "Tak",
            "Jakie leki/preparaty przyjmujesz?": "Ibuprofen",
            "Czy jesteś uczulony/-a na jakieś leki/preparaty?": "Nie",
            "Czy zdiagnozowano u Ciebie choroby przewlekłe lub choroby towarzyszące?": "Nie",
            "Wybierz z jakimi mierzysz się dolegliwościami": "Ból głowy",
            "Opisz w kilku zdaniach swoje objawy": "Częste bóle głowy w okolicy skroni.",
            "Wybierz wnioskowany lek": "Poproś lekarza o dobranie leku",
            "Czy posiadasz aktywne konto IKP?": "Tak",
            "Preferowany czas kontaktu": getRandomDateWithinLastDays(1),
            "registration_datetime": getRandomDateWithinLastDays(7),
            "status": "nierozpoczęta"
          },
          [getRandomNumber(1000, 100000)]: {
            "Imię": "Małgorzata",
            "Nazwisko": "Jotko",
            "Email": "malgorzata.jotko@example.com",
            "Telefon": "987-654-321",
            "PESEL": "92020254321",
            "Adres": "ul. Nowa 5",
            "Kod pocztowy": "02-222",
            "Miasto": "Kraków",
            "Płeć": "Kobieta",
            "Czy jest to kontynuacja leczenia?": "Nie",
            "Czy jesteś uzależniony/-a od jakichkolwiek substancji albo leków lub zażywasz te substancje obecnie?": "Tak",
            "Czy przyjmujesz obecnie jakiekolwiek leki/preparaty?": "Nie",
            "Czy jesteś uczulony/-a na jakieś leki/preparaty?": "Tak",
            "Na jakie leki/preparaty jesteś uczulony/-a?": "Penicylina",
            "Czy zdiagnozowano u Ciebie choroby przewlekłe lub choroby towarzyszące?": "Tak",
            "Wymień wszystkie choroby przewlekłe i choroby towarzyszące": "Cukrzyca",
            "Wybierz z jakimi mierzysz się dolegliwościami": "Cukrzyca",
            "Opisz w kilku zdaniach swoje objawy": "Brak specyficznych objawów, kontrola cukru we krwi.",
            "Wybierz wnioskowany lek": "Poproś lekarza o dobranie leku",
            "Czy posiadasz aktywne konto IKP?": "Nie",
            "Preferowany czas kontaktu": getRandomDateWithinLastDays(1),
            "registration_datetime": getRandomDateWithinLastDays(7),
            "status": "zrealizowana"
          },
          [getRandomNumber(1000, 100000)]: {
            "Imię": "Robert",
            "Nazwisko": "Zieliński",
            "Email": "robert.zielinski@example.com",
            "Telefon": "654-321-987",
            "PESEL": "91010176543",
            "Adres": "ul. Stara 3",
            "Kod pocztowy": "03-333",
            "Miasto": "Łódź",
            "Płeć": "Mężczyzna",
            "Czy jest to kontynuacja leczenia?": "Nie",
            "Czy jesteś uzależniony/-a od jakichkolwiek substancji albo leków lub zażywasz te substancje obecnie?": "Nie",
            "Czy przyjmujesz obecnie jakiekolwiek leki/preparaty?": "Nie",
            "Czy jesteś uczulony/-a na jakieś leki/preparaty?": "Nie",
            "Czy zdiagnozowano u Ciebie choroby przewlekłe lub choroby towarzyszące?": "Nie",
            "Wybierz z jakimi mierzysz się dolegliwościami": "Bezsenność",
            "Opisz w kilku zdaniach swoje objawy": "Trudności w zasypianiu i częste budzenie się w nocy.",
            "Wybierz wnioskowany lek": "Chcę sam wybrać lek z listy",
            "Wybierz lek z listy": "DELAHAZE (CANNABIS FLOS AURORA) 22% THC",
            "Czy posiadasz aktywne konto IKP?": "Tak",
            "registration_datetime": getRandomDateWithinLastDays(7),
            "status": "zrealizowana"
          }
        };

        const formattedRows = Object.entries(fetchedData).map(([form_id, form_data]) => ({
          patient: <Author image={patient} name={`${form_data["Imię"]} ${form_data["Nazwisko"]}`} email={form_data["Email"]} />,
          id: <Job description={`#${form_id}`} />,
          status: form_data['status'],
          registration_date: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {form_data['registration_datetime']}
            </MDTypography>
          ),
          preferred_contact_date: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {form_data['Preferowany czas kontaktu'] ? form_data['Preferowany czas kontaktu'] : 'Nie dotyczy'}
            </MDTypography>
          ),
          formId: form_id,
          formData: form_data,
          options: (
            <MDButton variant="contained" color="secondary" onClick={() => handleOpenModal(form_data)}>
              Pokaż dane
            </MDButton>
          ),
        }));
        const sortedRows = formattedRows.sort((a, b) => new Date(b.formData['registration_datetime']) - new Date(a.formData['registration_datetime']));
        setRows(sortedRows);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const fetchData = async () => {
      try {
        const { data: fetchedData } = await axios.get(`${API_BASE_URL}/api/v1/patients`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          }
        });

        const formattedRows = Object.entries(fetchedData).map(([form_id, form_data]) => ({
          patient: <Author image={patient} name={`${form_data["Imię"]} ${form_data["Nazwisko"]}`} email={form_data["Email"]} />,
          id: <Job description={`#${form_id}`} />,
          status: form_data['status'],
          registration_date: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {form_data['registration_datetime']}
            </MDTypography>
          ),
          preferred_contact_date: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {form_data['Preferowany czas kontaktu'] ? form_data['Preferowany czas kontaktu'] : 'Nie dotyczy'}
            </MDTypography>
          ),
          formId: form_id,
          formData: form_data,
          options: (
            <MDButton variant="contained" color="secondary" onClick={() => handleOpenModal(form_data)}>
              Pokaż dane
            </MDButton>
          ),
        }));
        const sortedRows = formattedRows.sort((a, b) => new Date(b.formData['registration_datetime']) - new Date(a.formData['registration_datetime']));
        setRows(sortedRows);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    auth.userName === "test123" ? fetchMockData() : fetchData();
  }, [auth]);

  const updateStatus = async (formId) => {
    try {
      if (auth.userName !== "test123") {
        // Update the status in the database
        await axios.put(`${API_BASE_URL}/api/v1/patients/${formId}`, { status: "zrealizowana" }, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          }
        });
      }

      // Update the status in the state
      setRows(rows.map(row => row.formId === formId ? {
        ...row,
        status: "zrealizowana",
        formData: { ...row.formData, status: "zrealizowana" }
      } : row));
    } catch (error) {
      console.error(error);
    }
  };

  return {
    loading,
    columns: [
      { Header: "pacjent", accessor: "patient", width: "45%", align: "left" },
      { Header: "id", accessor: "id", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "data rejestracji", accessor: "registration_date", align: "center" },
      { Header: "wizyta", accessor: "preferred_contact_date", align: "center" },
      { Header: "opcje", accessor: "options", align: "center" },
    ],
    rows,
    updateStatus,
  };
}
