// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

import axios from "axios"
import { useState, useEffect } from "react";
import moment from "moment";
import { API_BASE_URL } from "config/api";

const DOCTOR_APPOINTMENT_FEE = 60;

function Dashboard() {
  const auth = useAuthUser()

  const [todayPatients, setTodayPatients] = useState(0);
  const [patientDailyPercentageChange, setPatientDailyPercentageChange] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);
  const [todayEarnings, setTodayEarnings] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [weeklyPatients, setWeeklyPatients] = useState({});
  const [monthlyPatients, setMonthlyPatients] = useState({});
  const [patientMonthlyPercentageChange, setPatientMonthlyPercentageChange] = useState(0);
  const [recentAppointments, setRecentAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
        const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0]; // Get yesterday's date in YYYY-MM-DD format

        // Fetch total patients
        const totalResponse = await axios.get(`${API_BASE_URL}/api/v1/patients/count`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          }
        });

        // Fetch today's patients
        const todayResponse = await axios.get(`${API_BASE_URL}/api/v1/patients/count?date=${today}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          }
        });

        // Fetch yesterday's patients
        const yesterdayResponse = await axios.get(`${API_BASE_URL}/api/v1/patients/count?date=${yesterday}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          }
        });

        // Fetch weekly patients count
        const weeklyCountResponse = await axios.get(`${API_BASE_URL}/api/v1/patients/weekly-count`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          }
        });

        // Fetch monthly patients count
        const monthlyCountResponse = await axios.get(`${API_BASE_URL}/api/v1/patients/monthly-count`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          }
        });

        const recentAppointmentsResponse = await axios.get(`${API_BASE_URL}/api/v1/patients/recent`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
          params: {
            limit: 3
          }
        });

        const totalPatients = totalResponse.data.count;
        const todayPatients = todayResponse.data.count;
        const yesterdayPatients = yesterdayResponse.data.count;
        const weeklyPatients = weeklyCountResponse.data;
        const monthlyPatientsData = monthlyCountResponse.data;
        const recentAppointments = recentAppointmentsResponse.data

        setTotalPatients(totalPatients);
        setTodayPatients(todayPatients);
        setTotalEarnings(totalPatients * DOCTOR_APPOINTMENT_FEE);
        setTodayEarnings(todayPatients * DOCTOR_APPOINTMENT_FEE);
        setRecentAppointments(recentAppointments);

        // Calculate monthly change
        const currentMonthData = monthlyPatientsData[monthlyPatientsData.length - 1];
        const lastMonthData = monthlyPatientsData[monthlyPatientsData.length - 2];

        if (currentMonthData) {
          // If lastMonthData is not available, default to 100%
          const growth = lastMonthData ? ((currentMonthData.count - lastMonthData.count) / lastMonthData.count) * 100 : 100;
          setPatientMonthlyPercentageChange(growth.toFixed(2));
        }

        // Process weekly patients data
        const daysOfWeek = ["Nie", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"];
        const last7Days = [...Array(7)].map((_, i) => moment().subtract(6 - i, 'days').format('YYYY-MM-DD'));
        const patientCounts = last7Days.map(date => ({
          date,
          count: 0
        }));
        weeklyPatients.forEach(item => {
          const index = patientCounts.findIndex(pc => pc.date === item.date);
          if (index !== -1) {
            patientCounts[index].count = item.count;
          }
        });
        const weeklyPatientLabels = last7Days.map(date => daysOfWeek[moment(date).day()]);
        const weeklyPatientData = patientCounts.map(pc => pc.count);
        const weeklyPatientChart = {
          labels: weeklyPatientLabels,
          datasets: {
            label: "Pacjenci",
            data: weeklyPatientData,
          },
        };
        setWeeklyPatients(weeklyPatientChart);

        // Process monthly patients data
        const months = {
          "01": "Sty",
          "02": "Lut",
          "03": "Mar",
          "04": "Kwi",
          "05": "Maj",
          "06": "Cze",
          "07": "Lip",
          "08": "Sie",
          "09": "Wrz",
          "10": "Paź",
          "11": "Lis",
          "12": "Gru",
        };

        const last7Months = [...Array(7)].map((_, i) => moment().subtract(6 - i, 'months').format('YYYY-MM'));
        const monthlyEarningsCounts = last7Months.map(month => ({
          month,
          earnings: 0
        }));
        monthlyPatientsData.forEach(item => {
          const index = monthlyEarningsCounts.findIndex(mc => mc.month === item.month);
          if (index !== -1) {
            monthlyEarningsCounts[index].earnings = item.count * DOCTOR_APPOINTMENT_FEE;
          }
        });
        const monthlyLabels = last7Months.map(month => {
          const monthNumber = month.split("-")[1];
          return months[monthNumber];  // Display only the month name
        });
        const monthlyEarningsData = monthlyEarningsCounts.map(mc => mc.earnings);
        const monthlyEarningsChart = {
          labels: monthlyLabels,
          datasets: {
            label: "Zarobki (zł)",
            data: monthlyEarningsData,
          },
        };
        setMonthlyPatients(monthlyEarningsChart);

        // Calculate percentage changes for today vs. yesterday
        if (yesterdayPatients > 0) {
          const patientChange = ((todayPatients - yesterdayPatients) / yesterdayPatients) * 100;
          setPatientDailyPercentageChange(patientChange.toFixed(2));
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching patient data", error);
        setError(error.message);
        openErrorSB()
        setLoading(false);
      }
    };

    const fetchMockData = () => {
      const mockDoctorAppointmentFee = 30 + 10 * Math.floor(Math.random() * 4);
      const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
      // Helper function to generate a random date within the last few days
      const getRandomDateWithinLastDays = (days) => {
        const now = new Date();
        const randomDaysAgo = getRandomNumber(0, days); // Random number of days ago
        const randomDate = new Date(now.setDate(now.getDate() - randomDaysAgo));
        randomDate.setHours(getRandomNumber(0, 23), getRandomNumber(0, 59), getRandomNumber(0, 59));
        return randomDate.toISOString();
      };

      // Mock data generation
      const mockTotalPatients = getRandomNumber(50, 1000);
      const mockTodayPatients = getRandomNumber(3, 50);
      const mockYesterdayPatients = getRandomNumber(3, 50);
      const mockWeeklyPatients = [
        { date: new Date().toISOString().split('T')[0], count: 5 },  // Today
        { date: new Date(Date.now() - 86400000).toISOString().split('T')[0], count: 8 },  // Yesterday
        // Add more mock data for the past week if needed
      ];
      const mockMonthlyPatientsData = [
        { month: "2024-08", count: 30 },
        { month: "2024-07", count: 40 },
        // Add more mock data for past months if needed
      ];
      const mockRecentAppointments = [
        { formId: getRandomNumber(1000, 100000), Imię: "Michał", Nazwisko: "Kwiecień", registration_datetime: getRandomDateWithinLastDays(7), status: "nierozpoczęta" },
        { formId: getRandomNumber(1000, 100000), Imię: "Małgorzata", Nazwisko: "Jotko", registration_datetime: getRandomDateWithinLastDays(7), status: "zrealizowana" },
        { formId: getRandomNumber(1000, 100000), Imię: "Robert", Nazwisko: "Zieliński", registration_datetime: getRandomDateWithinLastDays(7), status: "zrealizowana" },
      ];
      mockRecentAppointments.sort((a, b) => new Date(b.registration_datetime) - new Date(a.registration_datetime));
      mockRecentAppointments[0].status = "nierozpoczęta";
      mockRecentAppointments[1].status = "zrealizowana";
      mockRecentAppointments[2].status = "zrealizowana";

      // Set state with mock data
      setTotalPatients(mockTotalPatients);
      setTodayPatients(mockTodayPatients);
      setTotalEarnings(mockTotalPatients * mockDoctorAppointmentFee);
      setTodayEarnings(mockTodayPatients * mockDoctorAppointmentFee);
      setRecentAppointments(mockRecentAppointments);

      // Calculate mock monthly change
      const currentMonthData = mockMonthlyPatientsData[mockMonthlyPatientsData.length - 1];
      const lastMonthData = mockMonthlyPatientsData[mockMonthlyPatientsData.length - 2];
      const growth = lastMonthData ? ((currentMonthData.count - lastMonthData.count) / lastMonthData.count) * 100 : 100;
      setPatientMonthlyPercentageChange(growth.toFixed(2));

      // Mock weekly patient data processing
      const daysOfWeek = ["Nie", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"];
      const last7Days = [...Array(7)].map((_, i) => moment().subtract(6 - i, 'days').format('YYYY-MM-DD'));
      const patientCounts = last7Days.map(date => ({
        date,
        count: Math.floor(Math.random() * 10) // Random count for mock data
      }));

      const weeklyPatientLabels = last7Days.map(date => daysOfWeek[moment(date).day()]);
      const weeklyPatientData = patientCounts.map(pc => pc.count);
      const weeklyPatientChart = {
        labels: weeklyPatientLabels,
        datasets: {
          label: "Pacjenci",
          data: weeklyPatientData,
        },
      };
      setWeeklyPatients(weeklyPatientChart);

      // Mock monthly patient data processing
      const months = {
        "01": "Sty",
        "02": "Lut",
        "03": "Mar",
        "04": "Kwi",
        "05": "Maj",
        "06": "Cze",
        "07": "Lip",
        "08": "Sie",
        "09": "Wrz",
        "10": "Paź",
        "11": "Lis",
        "12": "Gru",
      };
      const last7Months = [...Array(7)].map((_, i) => moment().subtract(6 - i, 'months').format('YYYY-MM'));
      const monthlyEarningsCounts = last7Months.map(month => ({
        month,
        earnings: Math.floor(Math.random() * 5000) // Random earnings for mock data
      }));
      const monthlyLabels = last7Months.map(month => {
        const monthNumber = month.split("-")[1];
        return months[monthNumber];  // Display only the month name
      });
      const monthlyEarningsData = monthlyEarningsCounts.map(mc => mc.earnings);
      const monthlyEarningsChart = {
        labels: monthlyLabels,
        datasets: {
          label: "Zarobki (zł)",
          data: monthlyEarningsData,
        },
      };
      setMonthlyPatients(monthlyEarningsChart);

      // Calculate mock percentage changes for today vs. yesterday
      if (mockYesterdayPatients > 0) {
        const patientChange = ((mockTodayPatients - mockYesterdayPatients) / mockYesterdayPatients) * 100;
        setPatientDailyPercentageChange(patientChange.toFixed(2));
      }

      setLoading(false);
    };

    auth.userName === "test123" ? fetchMockData() : fetchPatientData();
  }, [auth]);

  return (
    (auth && <DashboardLayout>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Błąd pobierania danych"
        content={error}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
      <DashboardNavbar />
      <MDBox py={3}>
        <MDTypography color="dark" fontWeight="bold">
          Witaj, {auth.displayName || "NIEZALOGOWANY"}!
        </MDTypography>
      </MDBox>
      {loading ? <MDBox display="flex" justifyContent="center" alignItems="center" height="70vh">
        <CircularProgress />
      </MDBox> : <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Pacjenci dzisiaj"
                count={todayPatients}
                percentage={{
                  color: patientDailyPercentageChange >= 0 ? "success" : "error",
                  amount: `${patientDailyPercentageChange >= 0 ? "+" : ""}${patientDailyPercentageChange}%`,
                  label: "w stosunku do wczoraj",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="sell"
                title="Zarobki dzisiaj"
                count={`${todayEarnings} zł`}
                percentage={{
                  color: patientDailyPercentageChange >= 0 ? "success" : "error",
                  amount: `${patientDailyPercentageChange >= 0 ? "+" : ""}${patientDailyPercentageChange}%`,
                  label: "w stosunku do wczoraj",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="leaderboard"
                title="Pacjenci łącznie"
                count={totalPatients}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="sell"
                title="Zarobki łącznie"
                count={`${totalEarnings} zł`}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Pacjenci"
                  description="Na przestrzeni ostatnich 7 dni"
                  date="Zaktualizowano dzisiaj"
                  chart={weeklyPatients}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Zarobki"
                  description={"Na przestrzeni ostatnich 7 miesięcy"}
                  date="Zaktualizowano dzisiaj"
                  chart={monthlyPatients}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <OrdersOverview recentAppointments={recentAppointments} monthlyPatientChange={patientMonthlyPercentageChange} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>}
      <Footer />
    </DashboardLayout >)
  );
}

export default Dashboard;
