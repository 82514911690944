/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Skeleton, Card } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import axios from "axios";
// Material Dashboard 2 React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import moment from "moment";
import { API_BASE_URL } from "config/api";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/pl";
import { plPL } from "@mui/x-date-pickers/locales";

const polishDaysOfWeek = {
  monday: "Poniedziałek",
  tuesday: "Wtorek",
  wednesday: "Środa",
  thursday: "Czwartek",
  friday: "Piątek",
  saturday: "Sobota",
  sunday: "Niedziela",
};
const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

function Calendar() {
  const auth = useAuthUser(); // Authentication token
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [calendarData, setCalendarData] = useState([]); // Initialize as empty array to avoid 'undefined' issues
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Dynamic message for snackbar
  const initialCalendarData = useRef(null); // To store the initial fetched data

  const openSuccessSB = (message) => {
    setSnackbarMessage(message);
    setSuccessSB(true);
  };
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = (message) => {
    setSnackbarMessage(message);
    setErrorSB(true);
  };
  const closeErrorSB = () => setErrorSB(false);

  const mergeWithFullWeek = (fetchedData) => {
    const dayOccurrences = {}; // Track occurrences of each day

    const filledData = daysOfWeek.flatMap((day) => {
      const occurrences = fetchedData.filter((item) => item.day_of_week === day);
      if (occurrences.length > 0) {
        return occurrences.map((item, occurrenceIndex) => ({
          ...item,
          additional: occurrenceIndex > 0, // Mark additional occurrences
        }));
      }

      return {
        id: Math.random(), // Use the index as the id for missing entries
        day_of_week: day,
        start_time: null,
        end_time: null,
      };
    });

    return filledData;
  };

  // Fetch initial calendar data
  useEffect(() => {
    async function fetchCalendar() {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/calendar`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });

        const fetchedData = response.data || []; // Ensure data is always an array
        const mergedData = mergeWithFullWeek(fetchedData);

        initialCalendarData.current = mergedData;

        setCalendarData(mergedData); // Assuming response contains data in this structure
        setLoading(false);
      } catch (error) {
        console.error("Error fetching calendar data", error);
        setError(error.message);
        openErrorSB("Wystąpił błąd podczas pobierania danych kalendarza.");
        setLoading(false);
      }
    }
    fetchCalendar();
  }, [auth.token]);

  // Handle time change
  const handleTimeChange = (newValue, id, type) => {
    setCalendarData((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [type]: newValue ? newValue.format("HH:mm:ss") : null } : item
      )
    );
  };

  const handleClear = (id) => {
    setCalendarData((prev) =>
      prev.map((item) => (item.id === id ? { ...item, start_time: null, end_time: null } : item))
    );
  };

  const handleAddSlot = (day) => {
    setCalendarData((prev) => {
      // Find the last index where day_of_week matches
      const lastIndex = prev.map((item) => item.day_of_week).lastIndexOf(day);

      // Create the new slot object
      const newSlot = {
        additional: true,
        id: Math.random(),
        day_of_week: day,
        start_time: null,
        end_time: null,
      };

      // Insert the new item after the last occurrence of the day_of_week
      return [
        ...prev.slice(0, lastIndex + 1), // All items before and including the found index
        newSlot, // The new item to be inserted
        ...prev.slice(lastIndex + 1), // All items after the found index
      ];
    });
  };

  // Remove second slot for a specific day
  const handleRemoveSlot = (id) => {
    setCalendarData(
      (prev) => prev.filter((item) => item.id !== id) // Remove the item(s) with matching day_of_week
    );
  };

  // useEffect to detect when calendarData changes and check if the data has changed
  useEffect(() => {
    if (!initialCalendarData.current) return setIsValid(false);
    if (calendarData.some((x) => (x.start_time && !x.end_time) || (x.end_time && !x.start_time)))
      return setIsValid(false);

    // Compare the current calendarData with the initial one
    if (calendarData.length === initialCalendarData.current.length) {
      if (JSON.stringify(calendarData) !== JSON.stringify(initialCalendarData.current)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else {
      if (calendarData.some((x) => !!x.additional && (!x.start_time || !x.end_time))) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [calendarData]); // This useEffect runs every time calendarData changes

  // Save all changes to the backend
  const handleSaveAll = async () => {
    // if id is not a number, delete it from payload
    const calendarDataFiltered = calendarData.map((item) => {
      if (isNaN(item.id)) {
        const { id, ...rest } = item;
        return rest;
      }
      return item;
    });

    try {
      await axios.put(
        `${API_BASE_URL}/api/v1/calendar`, // Ensure this endpoint supports bulk updates
        calendarDataFiltered, // Adjust the payload as needed
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      setIsValid(false);
      // Reset state and show success message
      openSuccessSB("Zmiany zostały zapisane pomyślnie.");
    } catch (error) {
      console.error("Error saving all times", error);
      openErrorSB("Wystąpił błąd podczas zapisywania zmian.");
    }
  };

  const findLatestEndTime = (id, day, calendarData) => {
    const entriesForDay = calendarData.filter(
      (entry) => entry.day_of_week === day && entry.id !== id
    );

    const endTimes = entriesForDay
      .map((entry) => entry.end_time)
      .filter((endTime) => endTime) // Remove null or undefined start_time values
      .map((endTime) => moment(endTime, "HH:mm:ss"));

    if (endTimes.length === 0) return null;
    const earliestendTime = moment.max(endTimes);
    // if (earliestendTime.isBefore(endTimeMoment)) return null;

    return earliestendTime; // Return Moment object
  };

  const findEarliestStartTime = (id, day, calendarData, startTime) => {
    const startTimeMoment = moment(startTime, "HH:mm:ss");
    const entriesForDay = calendarData.filter(
      (entry) => entry.day_of_week === day && entry.id !== id
    );

    const startTimes = entriesForDay
      .map((entry) => entry.start_time)
      .filter((startTime) => startTime) // Remove null or undefined start_time values
      .map((startTime) => moment(startTime, "HH:mm:ss"));

    if (startTimes.length === 0) return null;
    const earliestStartTime = moment.min(startTimes);
    if (earliestStartTime.isBefore(startTimeMoment)) return null;
    return earliestStartTime; // Return Moment object
  };

  return (
    <DashboardLayout>
      <MDSnackbar
        color="success"
        icon="check"
        title="Sukces!"
        content={snackbarMessage} // Dynamic success message
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title="Błąd!"
        content={snackbarMessage} // Dynamic error message
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Moje Godziny Pracy
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            {loading ? (
              <>
                <Skeleton variant="text" width="90%" sx={{ margin: "auto" }} height={60} />
                <Skeleton
                  variant="rectangular"
                  width="98%"
                  height={445}
                  sx={{ margin: "auto", mt: 3, mb: 2 }}
                />
              </>
            ) : (
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
              >
                <Grid
                  container
                  mb={3}
                  pb={2}
                  sx={{
                    borderBottom: "1px solid #e0e0e0",
                    fontSize: "0.9rem",
                  }}
                >
                  {/* Render headers */}
                  <Grid item xs={3} container justifyContent={"center"} alignItems={"center"}>
                    <strong>Dzień Tygodnia</strong>
                  </Grid>
                  <Grid item xs={3} container justifyContent={"center"} alignItems={"center"}>
                    <strong>Start Pracy</strong>
                  </Grid>
                  <Grid item xs={3} container justifyContent={"center"} alignItems={"center"}>
                    <strong>Koniec Pracy</strong>
                  </Grid>
                  <Grid item xs={3} container justifyContent={"center"} alignItems={"center"}>
                    <strong>Akcje</strong>
                  </Grid>
                </Grid>
                {/* Render rows for each day */}
                <Grid container spacing={3}>
                  {calendarData.map((dayData, index) => (
                    <React.Fragment key={dayData.id}>
                      <Grid
                        item
                        xs={3}
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          borderRight: "1px solid #e0e0e0",
                          fontSize: "1.1rem",
                          pb: index === calendarData.length - 1 ? 3 : 0,
                        }}
                      >
                        {dayData.additional ? null : (
                          <span>{polishDaysOfWeek[dayData.day_of_week]}</span>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          pb: index === calendarData.length - 1 ? 3 : 0,
                          mt: dayData.additional ? -1.5 : 0, // Conditional top margin
                        }}
                      >
                        {/* <TimePicker label="Basic time picker" /> */}
                        <TimePicker
                          label="Start Pracy"
                          // minTime={findLatestEndTime(dayData.id, dayData.day_of_week, calendarData)}
                          maxTime={dayData?.end_time ? moment(dayData.end_time, "HH:mm:ss") : null}
                          value={dayData.start_time ? moment(dayData.start_time, "HH:mm:ss") : null} // Use Moment.js to parse the time
                          onChange={(newValue) =>
                            handleTimeChange(newValue, dayData.id, "start_time")
                          }
                          renderInput={(params) => <TextField {...params} />}
                          sx={{
                            marginLeft: dayData.additional ? 3 : 0,
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: dayData.additional && !dayData.start_time && "red",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: dayData.additional && !dayData.start_time && "red",
                            },
                            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#1f78e9",
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          pb: index === calendarData.length - 1 ? 3 : 0,
                          mt: dayData.additional ? -1.5 : 0, // Conditional top margin
                        }}
                      >
                        <TimePicker
                          label="Koniec Pracy"
                          minTime={
                            dayData?.start_time ? moment(dayData.start_time, "HH:mm:ss") : null
                          }
                          // maxTime={findEarliestStartTime(
                          //   dayData.id,
                          //   dayData.day_of_week,
                          //   calendarData,
                          //   dayData.start_time
                          // )}
                          value={dayData.end_time ? moment(dayData.end_time, "HH:mm:ss") : null} // Use Moment.js to parse the time
                          onChange={(newValue) =>
                            handleTimeChange(newValue, dayData.id, "end_time")
                          }
                          renderInput={(params) => <TextField {...params} />}
                          sx={{
                            marginLeft: dayData.additional ? 3 : 0,
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: dayData.additional && !dayData.end_time && "red",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: dayData.additional && !dayData.end_time && "red",
                            },
                            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#1f78e9",
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        gap={2}
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          pb: index === calendarData.length - 1 ? 3 : 0,
                          mt: dayData.additional ? -1.5 : 0, // Conditional top margin
                          borderLeft: "1px solid #e0e0e0",
                        }}
                      >
                        {dayData.additional ? (
                          <MDButton
                            variant="contained"
                            color="secondary"
                            onClick={() => handleRemoveSlot(dayData.id)}
                          >
                            Usuń
                          </MDButton>
                        ) : (
                          <MDButton
                            variant="contained"
                            color="secondary"
                            disabled={dayData.start_time === null || dayData.end_time === null}
                            onClick={() => handleAddSlot(dayData.day_of_week)}
                          >
                            Dodaj Slot
                          </MDButton>
                        )}
                        <MDButton
                          variant="contained"
                          color="primary"
                          disabled={dayData.start_time === null && dayData.end_time === null}
                          onClick={() => handleClear(dayData.id)}
                        >
                          Wyczyść
                        </MDButton>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </LocalizationProvider>
            )}
          </MDBox>
        </Card>
      </MDBox>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "end",
          pb: 2, // for padding-bottom
        }}
      >
        <MDButton
          sx={{ width: 275 }} // Align the button to the right
          color={isValid ? "success" : "secondary"}
          variant="contained"
          onClick={() => handleSaveAll()}
          disabled={!isValid} // Disable the button until a change is made
        >
          Potwierdź zmiany
        </MDButton>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Calendar;
