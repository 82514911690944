/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge"

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";

// Modal
import PatientDetailsModal from "layouts/modals/patient-data"
import { useState } from "react"
// Import the new modal
import ReceiptConfirmationModal from 'layouts/modals/receipt-confirmation';

function Tables() {
  const { columns, rows, updateStatus, loading } = authorsTableData();
  const [openModal, setOpenModal] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [selectedFormId, setSelectedFormId] = useState(null);

  const handleOpenModal = (data) => {
    const { registration_datetime, "Preferowany czas kontaktu": preferred_contact_date, status, ...filteredData } = data.formData;
    setSelectedData({ ...data, formData: filteredData });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedData({});
  };

  const handleOpenReceiptModal = (formId) => {
    setSelectedFormId(formId);
    setReceiptModalOpen(true);
  };

  const handleCloseReceiptModal = () => {
    setReceiptModalOpen(false);
    setSelectedFormId(null);
  };

  const handleConfirmReceipt = (formId, receiptIssued) => {
    // Perform the status update based on receipt issuance
    updateStatus(formId, receiptIssued);
    handleCloseReceiptModal();
  };

  const rowsWithHandlers = rows.map(row => ({
    ...row,
    options: (
      <MDBox display="flex" alignItems="center">
        <MDBox mr={1}>
          <MDButton
            size="small"
            variant="contained"
            color="info"
            onClick={() => handleOpenModal(row)}
          >
            Zobacz dane
          </MDButton>
        </MDBox>
        <MDBox ml={1}>
          <MDButton
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => handleOpenReceiptModal(row.formId)}
            disabled={row.status === "zrealizowana"}
          >
            Odznacz wizytę
          </MDButton>
        </MDBox>
      </MDBox>
    ),
    status: (
      <MDBox ml={-1}>
        <MDBadge badgeContent={row.status} color={row.status === "zrealizowana" ? "success" : "dark"} variant="gradient" size="sm" />
      </MDBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Moje wizyty
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {loading ?
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="25vh">
                    <CircularProgress />
                  </MDBox>
                  :
                  <DataTable
                    table={{ columns, rows: rowsWithHandlers }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <PatientDetailsModal
          open={openModal}
          handleClose={handleCloseModal}
          formId={selectedData.formId}
          formData={selectedData.formData}
        />
        <ReceiptConfirmationModal
          open={receiptModalOpen}
          handleClose={handleCloseReceiptModal}
          handleConfirm={handleConfirmReceipt}
          formId={selectedFormId}
        />
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default Tables;
