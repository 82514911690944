/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import moment from "moment";

function OrdersOverview({ recentAppointments, monthlyPatientChange }) {
  console.log("recentAppointments", recentAppointments)
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Szybki przegląd ostatnich wizyt
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: monthlyPatientChange >= 0 ? 'success.main' : 'error.main' }}>
                {monthlyPatientChange >= 0 ? 'arrow_upward' : 'arrow_downward'}
              </Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              {monthlyPatientChange}%
            </MDTypography>{" "}
            w tym miesiącu
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {recentAppointments.map((appointment, index) => (
          <TimelineItem
            key={index}
            color={appointment.status === "zrealizowana" ? "success" : "warning"}
            icon={appointment.status === "zrealizowana" ? "check" : "notifications"}
            title={`${appointment['Imię']} ${appointment['Nazwisko']} #${appointment.formId}`}
            dateTime={moment(appointment.registration_datetime).format("YYYY-MM-DD HH:mm:ss")}
          />
        ))}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
